<template>
  <div>
    <Card>
      <template #title>Historico Funcional (Diretas)</template>
      <template #content>
        <div class="p-fluid grid">
          <div class="field col-12 md:col-6">
            <Dropdown
              v-model="matricula"
              :options="matriculasList"
              data-key="id"
              placeholder="Matrícula"
              style="width: 100%"
              @change="getHistoricoFuncionalByMatricula">
              <template #value="slotProps">
                <div v-if="slotProps.value">
                  <span>
                    {{ slotProps.value.matricula }} -
                    {{ slotProps.value.entidadeNome }}
                  </span>
                </div>
              </template>
              <template #option="slotProps">
                <span class="card-desktop">
                  {{ slotProps.option.matricula }} -
                  {{ slotProps.option.entidadeNome }}
                </span>
                <span class="card-mobile">
                  {{ slotProps.option.matricula }} -
                  {{ slotProps.option.entidadeNome | limitadorString }}
                </span>
              </template>
            </Dropdown>
          </div>
        </div>
        <!-- <div class="p-fluid grid mb-1">
          <div class="col-6 md:col-2">
            <span class="p-float-label">
              <Calendar v-model="dataInicial" dateFormat="dd/mm/yy" />
              <label for="Data Inicial">Data Inicial</label>
            </span>
          </div>
          <div class="col-6 md:col-2">
            <span class="p-float-label">
              <Calendar v-model="dataFinal" dateFormat="dd/mm/yy" />
              <label for="Data Final">Data Final</label>
            </span>
          </div>
          <div class="col-12 md:col-2">
            <Button
              label="Buscar"
              :disabled="dataFinal == null || dataInicial == null"
              @click="resetDadosEFiltrarTabela" />
          </div>
        </div> -->
        <div class="mt-5">
          <DataTable
            stripedRows
            :value="historicoList"
            :loading="loading"
            responsiveLayout="stack">
            <template #empty> Nenhum histórico encontrado </template>
            <template #loading> Carregando. Por favor aguarde. </template>

            <Column
              field="descricaoNatureza"
              header="Natureza do Histórico"></Column>
            <Column field="complemento" header="Descrição"></Column>
            <Column header="Data do Documento" :sortable="true">
              <template #body="{ data }">
                {{ data.dataDocumento | formatarData }}
              </template>
            </Column>
            <!-- <Column header="Data da Publicação" :sortable="true">
              <template #body="{ data }">
                {{ data.dataPublicacao | formatarData }}
              </template>
            </Column> -->
          </DataTable>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Moment from 'moment'
import MatriculaService from '@/service/MatriculaService.js'
import PortalServidorService from '@/service/PortalServidorService'

export default {
  data() {
    return {
      matriculasList: [],
      matricula: null,
      dataInicial: null,
      dataFinal: null,
      loading: false,
      historicoList: [],
    }
  },

  created() {
    this.matriculaService = new MatriculaService(this.$http)
    this.portalServidorService = new PortalServidorService(this.$http)
  },

  mounted() {
    this.loading = true
    this.carregarDados()
  },

  methods: {
    getHistoricoFuncionalByMatricula() {
      this.portalServidorService
        .getHistoricoFuncionalByMatricula(this.matricula.matricula)
        .then((res) => {
          this.historicoList = res
          this.historicoList = this.historicoList.sort((a, b) => {
            const dateA = Moment(a.dataDocumento, 'YYYY-MM-DD').toDate()
            const dateB = Moment(b.dataDocumento, 'YYYY-MM-DD').toDate()
            return dateB - dateA
          })
        })
    },
    carregarDados() {
      this.loading = true
      this.matriculaService
        .getMatriculasUsuarioLogado()
        .then((res) => {
          this.getDadosFuncionaisByCpf(res[0].cpf)
          this.limpar()
        })
        .catch((err) => {
          const mensagem = err.response
            ? err.response.data.message
            : 'Erro de conexão com a aplicação.'
          this.limpar()
          this.$toast.add({
            severity: 'error',
            summary: mensagem,
            life: 10000,
          })
        })
    },
    getDadosFuncionaisByCpf(matricula) {
      this.portalServidorService
        .getDadosFuncionaisByCpf(matricula)
        .then((res) => {
          this.matriculasList = res
          for (const idx in this.matriculasList) {
            this.matriculasList[idx].id = parseInt(idx)
          }
          this.matricula = res[0]
          this.getHistoricoFuncionalByMatricula()
        })
        .catch((err) => {
          const mensagem = err.response
            ? err.response.data.message
            : 'Erro de conexão com a aplicação.'
          this.limpar()
          this.$toast.add({
            severity: 'error',
            summary: mensagem,
            life: 10000,
          })
        })
    },
    limpar() {
      this.loading = false
    },
    async resetDadosEFiltrarTabela() {
      this.getHistoricoFuncionalByMatricula()
      await new Promise((resolve) => setTimeout(resolve, 300))
      this.filtrarTabela()
    },
    filtrarTabela() {
      const startDate = Moment(
        this.$options.filters.formatarData(this.dataInicial),
        'DD/MM/YYYY',
      )
      const endDate = Moment(
        this.$options.filters.formatarData(this.dataFinal),
        'DD/MM/YYYY',
      )

      this.historicoList = this.historicoList.filter((item) => {
        const itemDate = Moment(
          this.$options.filters.formatarData(item.dataDocumento),
          'DD/MM/YYYY',
        )
        return itemDate.isBetween(startDate, endDate, null, '[]')
      })
    },
  },
}
</script>
